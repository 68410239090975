import { Collapse, CollapseProps, Input } from 'antd'
import { SearchProps } from 'antd/es/input'
import NoTaskPlaceholder from 'components/NoTaskPlaceholder'
import { useEffect, useState } from 'react'
import { TaskLists } from 'services/Tasks.slice'
import { DelegationType, TaskItem } from 'types/Tasks'
import CustomPanelHeader from '../CustomPanelHeader'
import DelegatePanel from './DelegatePanel'
import DelegatedTaskHeaderSummary from './DelegatedTaskHeaderSummary'
import DelegatedTaskHeatmap from './DelegatedTaskHeatmap'
import styles from './DelegatedTaskList.module.scss'
import useStore from 'hooks/useStore'
import { useTranslation } from 'react-i18next'

const { Search } = Input

function divideTasksByUser(
  tasks: TaskItem[],
  filterString: string,
  pending: boolean,
  currentUserId?: string,
): TaskLists {
  const validateDuplicatedTasks: { [key: string]: boolean } = {}
  const taskList: TaskLists = {}
  const filteredList = filterString
    ? tasks.filter(
        (task) =>
          task.title.toLowerCase().includes(filterString.toLowerCase()) ||
          !!task.taskAssignees?.find((user) =>
            user.email?.toLowerCase()?.includes(filterString.toLowerCase()),
          ) ||
          !!task.pendingAssignees?.find((user) =>
            user.email?.toLowerCase()?.includes(filterString.toLowerCase()),
          ),
      )
    : tasks
  filteredList.forEach((task) => {
    const isMultipleDelegated =
      !pending &&
      task.manager?.id === currentUserId &&
      (task.delegationType === DelegationType.INDIVIDUAL_PARENT ||
        task.delegationType === DelegationType.SHARED)
    const userId = isMultipleDelegated
      ? // Use Delegation Type for showing the Individual Parent and Shared task in Delegated Tab
        task.delegationType
      : pending
      ? // If listing pending tasks, group by Manager
        task.manager.name
      : // It's ok to assume a single assignee because the shared tasks are handled under isMultipleDelegated
        task.taskAssignees?.[0]?.email?.toLowerCase() ||
        task.pendingAssignees?.[0]?.email?.toLocaleLowerCase()
    if (userId) {
      if (!taskList[userId]) {
        taskList[userId] = []
      }
      if (!validateDuplicatedTasks[task.id]) {
        taskList[userId].push(task as TaskItem)
        validateDuplicatedTasks[task.id] = true
      }
    }
  })
  return taskList
}

const sortEmailList = (emailList: string[]) => {
  const specialValues: string[] = [
    DelegationType.INDIVIDUAL_PARENT,
    DelegationType.SHARED,
  ]
  const special = emailList.filter((item) => specialValues.includes(item))
  const others = emailList.filter((item) => !specialValues.includes(item))
  special.sort((a, b) => specialValues.indexOf(a) - specialValues.indexOf(b))
  others.sort()
  return [...special, ...others]
}

type DelegatedTaskListProps = {
  tasks?: TaskItem[]
  onDismiss?: (task: TaskItem) => void
  pending?: boolean
}

const DelegatedTaskList = ({
  tasks,
  onDismiss,
  pending = false,
}: DelegatedTaskListProps) => {
  const { t } = useTranslation()
  const [taskList, setTaskList] = useState<TaskLists>({})
  const [searchValue, setSearchValue] = useState('')
  const [activeKeys, setActiveKeys] = useState<string[]>([])
  const user = useStore((state) => state.user)

  const onChange = (key: string | string[]) => {
    const newKeys = Array.isArray(key) ? key : [key]
    setActiveKeys(newKeys)
  }

  const onSearch: SearchProps['onSearch'] = (value, _, info) => {
    setSearchValue(value)
  }

  useEffect(() => {
    setTaskList(
      divideTasksByUser(tasks || [], searchValue, pending, user?.data?.id),
    )
  }, [searchValue, tasks, pending, user?.data?.id])

  const getItems = (
    tasks: TaskLists,
    activeKeys: string[],
    isExpanded: boolean,
  ) => {
    const items: CollapseProps['items'] = []
    if (!tasks) {
      return items
    }
    sortEmailList(Object.keys(tasks)).forEach((key) => {
      // I'm using the first record only for sorting purposes
      let title =
        tasks[key]?.[0]?.taskAssignees?.[0]?.email ||
        tasks[key]?.[0]?.pendingAssignees?.[0]?.email
      if (pending) {
        title = tasks[key]?.[0]?.user.name
      } else if (key === DelegationType.INDIVIDUAL_PARENT) {
        title = t('my-today-page.delegated-tab.individual-parent')
      } else if (key === DelegationType.SHARED) {
        title = t('my-today-page.delegated-tab.shared')
      }
      const count = tasks[key]?.length || 0
      items.push({
        key: key,
        label: pending ? (
          <CustomPanelHeader
            title={`${title} (${count})`}
            isActive={activeKeys.includes(key)}
          />
        ) : (
          <CustomPanelHeader
            title={`${title} `}
            isActive={activeKeys.includes(key)}
            prefix={<DelegatedTaskHeaderSummary tasks={tasks[key]} />}
            suffix={<DelegatedTaskHeatmap tasks={tasks[key]} />}
          />
        ),
        showArrow: false,
        className: styles.customPanel,
        children: (
          <DelegatePanel
            tasks={tasks[key]}
            isExpanded={isExpanded}
            isReadonly={true}
            onDismiss={onDismiss}
          />
        ),
      })
    })
    return items
  }

  return (
    <div className={styles.tasksListWrapper}>
      <Search
        placeholder="Search"
        style={{ width: '100%', marginBottom: '8px' }}
        onSearch={onSearch}
        enterButton
        allowClear
      />
      {Object.keys(taskList).length ? (
        <Collapse
          activeKey={activeKeys}
          onChange={onChange}
          className={styles.taskPanel}
          items={getItems(taskList, activeKeys, false)}
        />
      ) : (
        <NoTaskPlaceholder />
      )}
    </div>
  )
}

export default DelegatedTaskList
