import { Card as AntdCard, Space } from 'antd'
import clsx from 'clsx'
import { getIconByGroup } from 'components/CheckboxIconTag'
import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import React, { useState } from 'react'
import { Handle, Node, Position } from 'reactflow'
import 'reactflow/dist/style.css'
import { FlexibleTime } from 'types/FlexibleTime'
import {
  RoleMappingForTaskNode,
  ScheduledTask,
  TaskAssignees,
  TaskNodeData,
  TaskPriority,
} from 'types/Tasks'
import { getFlagTitleById } from 'utils/taskUtils'
import { TaskEvents } from '../../FlowUtils'
import styles from '../../TaskFlowViewer.module.scss'
import CardIcons from '../CardIcons'
import CardTitle from '../CardTitle'
import { useTaskNodeData } from './useTaskNodeData'
import CardFooter from '../CardFooter'
import { TaskFields } from 'utils/permissions'

const Card = (taskNode: Node<TaskNodeData>) => {
  const data = taskNode.data

  const {
    dueDateTooltip,
    groupIconName,
    groupName,
    hasGroup,
    hasPermissionToUpdateField,
    iconColor,
    isActive,
    isCompleted,
    isDraft,
    isRoutineDefinition,
    isRoutineInstance,
    isRunning,
    isScheduled,
    isSubtaskOfRoutine,
    isTemplate,
    isUrgent,
    isIssue,
    levelForColor,
    schedule,
    taskRoleMapping,
    userTemplateRole,
  } = useTaskNodeData(data)

  const [flexibleTime, setFlexibleTime] = useState<FlexibleTime | undefined>(
    data.scheduledTask?.flexibleTime || FlexibleTime.BEGINNING,
  )
  const [hidden, setHidden] = useState(false)
  const [routineIsOpen, setRoutineIsOpen] = useState(false)

  const { setChildNodeEvent } = useStore((state) => ({
    setChildNodeEvent: state.setChildNodeEvent,
  }))

  const addChildTask = (params: Node<TaskNodeData>) => {
    setChildNodeEvent({ event: TaskEvents.ADD, node: params })
  }

  const collapseExpand = (params: Node<TaskNodeData>) => {
    setChildNodeEvent({
      event: hidden ? TaskEvents.EXPAND : TaskEvents.COLLAPSE,
      node: params,
    })
    setHidden(!hidden)
  }

  const dueDateChanged = (
    params: Node<TaskNodeData>,
    newValue?: dayjs.Dayjs,
  ) => {
    const dueAt = newValue ? newValue.toISOString() : null
    params = {
      ...params,
      data: {
        ...params.data,
        dueAt,
      },
    }
    setChildNodeEvent({ event: TaskEvents.DUE_DATE_CHANGED, node: params })
  }

  const makeNodeEditable = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    params: Node<TaskNodeData>,
  ) => {
    e.stopPropagation()
    setChildNodeEvent({ event: TaskEvents.BEGIN_EDIT_NODE, node: params })
  }

  const priorityChanged = (params: Node<TaskNodeData>, newValue: any) => {
    params = {
      ...params,
      data: {
        ...params.data,
        taskDetails: {
          ...params.data.taskDetails,
          flag: { id: newValue, title: getFlagTitleById(newValue) },
        },
      },
    }
    setChildNodeEvent({ event: TaskEvents.PRIORITY_CHANGED, node: params })
  }

  const scheduleChanged = (
    params: Node<TaskNodeData>,
    newValue?: Partial<ScheduledTask> | null,
  ) => {
    const scheduledTask = newValue ? newValue : null
    params = { ...params, data: { ...params.data, scheduledTask } }
    setChildNodeEvent({ event: TaskEvents.SCHEDULE_CHANGED, node: params })
  }

  const priorityGroupChanged = (
    params: Node<TaskNodeData>,
    newValue?: number,
  ) => {
    params = {
      ...params,
      data: {
        ...params.data,
        priorityGroup: newValue,
      },
    }
    setChildNodeEvent({
      event: TaskEvents.PRIORITY_GROUP_CHANGED,
      node: params,
    })
  }

  const showTaskEditor = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    params: Node<TaskNodeData>,
  ) => {
    e.stopPropagation()
    setChildNodeEvent({ event: TaskEvents.EDIT, node: params })
  }

  const taskRoleChanged = (
    params: Node<TaskNodeData>,
    newValue?: RoleMappingForTaskNode,
  ) => {
    params = {
      ...params,
      data: {
        ...params.data,
        taskRole: { ...newValue },
      },
    }
    setChildNodeEvent({
      event: TaskEvents.TASK_ROLE_CHANGED,
      node: params,
    })
  }

  const cardContainerClassName = clsx(
    styles.cardContainer,
    taskNode.selected && styles['shadowLevel' + levelForColor],
  )

  const cardNodeClassName = clsx(
    styles.cardNode,
    styles['level' + levelForColor],
    isCompleted && styles.cardNodeCompleted,
    isCompleted && styles['cardModeCompleted' + levelForColor],
    isDraft && styles.cardModeDraft,
    isCompleted && styles.cardModeCompleted,
    isIssue && styles.cardIssue,
  )

  const dueDateIconClassName = clsx(
    styles.iconStyle,
    isDraft && styles.cardModeDraft,
    isCompleted && styles.cardModeCompleted,
    isActive && data.dueAt
      ? styles['colorLevel' + levelForColor]
      : styles.iconDisabled,
  )

  const iconClassName = clsx(
    styles.iconStyle,
    isDraft && styles.cardModeDraft,
    isCompleted && styles.cardModeCompleted,
    isActive && styles['colorLevel' + levelForColor],
  )

  const iconFlagClassName = clsx(
    styles.iconStyle,
    isDraft && styles.cardModeDraft,
    isCompleted && styles.cardModeCompleted,
    isActive && isUrgent ? styles.flagIcon : styles.iconDisabled,
  )

  const routineIconClassName = clsx(
    styles.iconStyle,
    isDraft && styles.cardModeDraft,
    isCompleted && styles.cardModeCompleted,
    isActive && (isRoutineDefinition || isRoutineInstance)
      ? styles['colorLevel' + levelForColor]
      : styles.iconDisabled,
  )

  const scheduledClassName = clsx(
    styles.iconStyle,
    isDraft && styles.cardModeDraft,
    isCompleted && styles.cardModeCompleted,
    isActive && isScheduled
      ? styles['colorLevel' + levelForColor]
      : styles.iconDisabled,
  )

  const groupIcon = getIconByGroup(groupIconName, iconClassName)

  return (
    <>
      {data.parent && (
        <Handle
          type="target"
          position={taskNode.targetPosition ?? Position.Top}
        />
      )}
      <div className={cardContainerClassName}>
        <AntdCard
          className={cardNodeClassName}
          style={taskNode.style}
          onDoubleClick={(e) => {
            showTaskEditor(e, taskNode)
            e.preventDefault()
          }}
        >
          <Space
            align="center"
            direction="vertical"
            size="small"
            style={{ display: 'flex' }}
          >
            <CardTitle
              isCompleted={isCompleted}
              isRunning={isRunning}
              iconColor={iconColor}
              iconClassName={iconClassName}
              title={data.title}
              onDoubleClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                if (hasPermissionToUpdateField(TaskFields.TITLE, data.roles)) {
                  makeNodeEditable(e, taskNode)
                  e.preventDefault()
                }
              }}
            />
            <CardIcons
              dueDateIconClassName={dueDateIconClassName}
              dueDateTooltip={dueDateTooltip}
              flexibleTime={flexibleTime}
              groupIcon={groupIcon}
              groupName={groupName}
              hasChildren={data.hasChildren}
              hasGroup={hasGroup}
              hasPermissionToUpdateField={hasPermissionToUpdateField}
              hidden={hidden}
              iconClassName={iconClassName}
              iconFlagClassName={iconFlagClassName}
              isRoutineInstance={isRoutineInstance}
              isSubtaskOfRoutine={isSubtaskOfRoutine}
              isUrgent={isUrgent}
              isIssue={isIssue}
              onAddChildTask={() => addChildTask(taskNode)}
              onCollapseExpand={() => collapseExpand(taskNode)}
              onDueDateChanged={(value) => dueDateChanged(taskNode, value)}
              onPriorityChanged={(value) =>
                priorityChanged(
                  taskNode,
                  isUrgent ? TaskPriority.NORMAL : TaskPriority.URGENT,
                )
              }
              onScheduleChanged={(value) => scheduleChanged(taskNode, value)}
              roles={data.roles}
              routineIconClassName={routineIconClassName}
              routineIsOpen={routineIsOpen}
              schedule={schedule}
              scheduledClassName={scheduledClassName}
              setFlexibleTime={setFlexibleTime}
              setRoutineIsOpen={setRoutineIsOpen}
              pendingAssignees={data.pendingAssignees}
              taskAssignees={data.taskAssignees as TaskAssignees[]}
            />
            {(isTemplate || isDraft) && (
              <CardFooter
                isDraft={isDraft}
                priorityGroup={data.priorityGroup}
                onPriorityGroupChange={(value) =>
                  priorityGroupChanged(taskNode, value)
                }
                roleMapping={taskRoleMapping}
                templateRoles={userTemplateRole}
                onRoleMappingChange={(value) =>
                  taskRoleChanged(taskNode, value)
                }
              />
            )}
          </Space>
        </AntdCard>
      </div>
      <Handle
        type="source"
        position={taskNode.sourcePosition ?? Position.Bottom}
      />
    </>
  )
}

export const MemoizedCard = React.memo(Card)
