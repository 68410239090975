import dayjs from 'dayjs'
import { useEffect } from 'react'

type TimeTriggerProps = {
  dateTimeToCompare: string
  orderedTimeTriggerList: string[]
  onTriggerEvent: () => void
  intervalInMinutes?: number
}

function useTimeTrigger({
  dateTimeToCompare,
  orderedTimeTriggerList,
  onTriggerEvent,
  intervalInMinutes = 1,
}: TimeTriggerProps) {
  useEffect(() => {
    let interval = setInterval(() => {
      let referenceDateTime = dayjs().hour(0).minute(0).second(0).millisecond(0)
      for (const time of orderedTimeTriggerList) {
        if (!dayjs(time, 'HH:mm').isValid()) {
          continue
        }
        const timejs = dayjs(time, 'HH:mm')
        const triggerDate = dayjs()
          .hour(timejs.hour())
          .minute(timejs.minute())
          .second(0)
          .millisecond(0)
        if (dayjs().isAfter(triggerDate, 'second')) {
          referenceDateTime = triggerDate
        }
      }
      if (referenceDateTime.isAfter(dateTimeToCompare)) {
        onTriggerEvent()
      }
    }, intervalInMinutes * 60000)

    return () => clearInterval(interval)
  }, [
    dateTimeToCompare,
    intervalInMinutes,
    onTriggerEvent,
    orderedTimeTriggerList,
  ])
}

export default useTimeTrigger
