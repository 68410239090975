import { createWithEqualityFn } from 'zustand/traditional'

type State = {
  subscriptionPackage: string
  billingUrl: string
}

type Action = {
  updateSubscriptionPackage: (value: string) => void
  updateBillingUrl: (value: string) => void
}

const useSubscription = createWithEqualityFn<State & Action>((set) => ({
  subscriptionPackage: '',
  billingUrl: '',
  updateSubscriptionPackage: (value) =>
    set(() => ({
      subscriptionPackage: value,
    })),
  updateBillingUrl: (value) =>
    set(() => ({
      billingUrl: value,
    })),
}))

export default useSubscription
