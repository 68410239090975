import { App, Popover } from 'antd'
import clsx from 'clsx'
import { colorList, iconList } from 'components/AccountGroupFilter/GroupForm'
import DateSelectorModal from 'components/DateSelectorModal'
import GroupDropdown from 'components/GroupDropdown/GroupDropdown'
import StatusDropdown from 'components/StatusDropdown'
import { motion } from 'framer-motion'
import useStore from 'hooks/useStore'
import { decode } from 'html-entities'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTaskEditor } from 'sections/TaskEditorModal'
import { TaskMode, TaskType } from 'services/Tasks.slice'
import {
  TaskItem,
  TaskItemWithCuriculumId,
  TaskPriority,
  TaskStatusId,
} from 'types/Tasks'
import { TaskFields, hasPermission } from 'utils/permissions'
import {
  extractChecklistsWithCheckedBoxes,
  getCheckListItemsCount,
} from 'utils/richText'
import {
  convertTaskStatusToCurriculumStatus,
  extractTextFromJson,
  getDateFromTitle,
  getGroupNameWithOwnerIfNeeded,
  isEmailIncludedInList,
  isInstanceBeforeToday,
  isJson,
} from 'utils/taskUtils'
import PlanningToolbar from './PlanningToolbar'
import styles from './TaskListItem.module.scss'
import TaskToolbar from './TaskToolbar'
import CompletedTaskToolbar from './CompletedTaskToolbar'

type TaskProps = {
  data: TaskItemWithCuriculumId
  index: number
  isExpanded?: boolean
  isReadonly?: boolean
  isDragging?: boolean
  hideStatus?: boolean
  isCurriculumTask?: boolean
  isCurriculumView?: boolean
  onViewTaskEditor?: (task: TaskItem) => void
  onViewFlowViewer?: (task: TaskItem) => void
  showChatIcon?: boolean
  onToggle?: (taskId: string) => void
  onDismiss?: (task: TaskItem) => void
}

export default function TaskListItem({
  data,
  isExpanded,
  isReadonly,
  isDragging,
  hideStatus,
  isCurriculumTask,
  isCurriculumView,
  onViewTaskEditor,
  onViewFlowViewer,
  showChatIcon,
  onToggle,
  onDismiss,
}: Readonly<TaskProps>) {
  const { t } = useTranslation()
  const app = App.useApp()
  const [color, setColor] = useState(colorList[0])
  const [icon, setIcon] = useState(iconList[0])
  const [minDate, setMinDate] = useState('')
  const [showDateSelector, setShowDateSelector] = useState(false)
  const [modalPromiseResolve, setModalPromiseResolve] = useState<
    ((value: string | PromiseLike<string>) => void) | null
  >(null)

  const { open, openCurriculumModal } = useTaskEditor()
  const selectedStatus = data?.status?.id
  const flagged = data?.taskDetails?.flag?.id === TaskPriority.URGENT
  const isCompletedTask = data?.status?.id === TaskStatusId.DONE
  const {
    selectedCurriculumTab,
    setShouldUpdateTasks,
    taskRolePermissions,
    tasks,
    updateCurriculumLesson,
    updateTask,
    user,
  } = useStore((state) => state)
  const { groups } = tasks
  const parentTitle =
    !onViewFlowViewer &&
    data?.parent?.title &&
    data?.parent?.title !== data?.title &&
    !data?.title?.includes(' -> ')
      ? data?.parent?.title + ' -> '
      : ''
  const title = data?.title

  const checkListItems = getCheckListItemsCount(
    data?.taskDetails?.description || '',
  )

  const htmlChecklist = extractChecklistsWithCheckedBoxes(
    data?.taskDetails?.description || '',
  )

  const htmlChecklistChecked = htmlChecklist.filter(
    (item) => item.checked === 'true',
  )

  const { done, count }: { done?: number; count?: number } =
    htmlChecklist.length > 0
      ? { done: htmlChecklistChecked.length, count: htmlChecklist.length }
      : checkListItems || { done: undefined, count: undefined }
  const completedItemsMessage = count && ` ${done} of ${count} items completed`
  const curriculumInstanceId = data.curriculumTaskInstance?.id
  // Cannot reassign the group of a curriculum lesson outside of the curriculum
  const isCurriculumLesson = !!curriculumInstanceId

  // Used in Planning page
  const showAdditionalLine = hideStatus && completedItemsMessage

  const isDelegatedToMe =
    data.user?.id !== user?.data.id &&
    isEmailIncludedInList(data?.taskAssignees, user?.data.email)

  const canEditGroup = useMemo(() => {
    return hasPermission(TaskFields.GROUP, data?.roles, taskRolePermissions)
  }, [data?.roles, taskRolePermissions])
  const canEditStatus = useMemo(() => {
    return hasPermission(TaskFields.STATUS, data?.roles, taskRolePermissions)
  }, [data?.roles, taskRolePermissions])

  useEffect(() => {
    if (data.group?.id && tasks.groups) {
      const group = tasks.groups.find((group) => {
        return group.id === data.group.id
      })
      if (group) {
        if (group.metadata.color) setColor(group.metadata.color)
        const icon = iconList.find(
          (icon) => icon.value === group.metadata?.icon,
        )
        if (icon) setIcon(icon)
      }
    }
  }, [data.group, tasks.groups])

  const getUserDateInput = () => {
    setShowDateSelector(true)
    return new Promise<string>((resolve) => {
      setModalPromiseResolve(() => resolve)
    })
  }

  const handleStatusChange = async (newStatus: TaskStatusId) => {
    const isCompletedSelected = newStatus === TaskStatusId.DONE

    let updatedProperties: TaskType = {
      status: newStatus,
    }

    if (isCompletedSelected) {
      if (data.isCustomValueRequired) {
        app.notification.warning({
          message: t('my-today.custom-value-required', {
            ns: 'validation',
          }),
        })
        return
      }

      updatedProperties.completionAt = new Date().toISOString()
      updatedProperties.mode = TaskMode.COMPLETED
      const isDateDifferent = isInstanceBeforeToday(data.title)
      if (curriculumInstanceId && isDateDifferent) {
        const minDate = getDateFromTitle(data.title)
        if (minDate) {
          setMinDate(minDate)
          const dateInput = await getUserDateInput()
          updatedProperties.completionAt = dateInput
        }
      }
    }
    updateTask(
      {
        taskId: data.id,
        data: updatedProperties,
      },
      user!.data.id,
    ).then(() => {
      const curriculumStatus = convertTaskStatusToCurriculumStatus(newStatus)
      if (curriculumInstanceId && curriculumStatus) {
        updateCurriculumLesson(curriculumInstanceId, {
          status: curriculumStatus,
        })
      }
    })
  }

  const handleTaskClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    if (isCurriculumTask) {
      openCurriculumModal(
        {
          ...data,
          isUpdate: true,
          isLibraryView: selectedCurriculumTab === 'library',
        },
        () => setShouldUpdateTasks,
      )
    } else {
      open(data, () => setShouldUpdateTasks(true))
    }
  }

  const handleGroupChange = async (value: number = 0) => {
    const updatedProperties = {
      group: value,
    }
    await updateTask(
      {
        taskId: data.id,
        data: updatedProperties,
      },
      user!.data.id,
    )
  }

  const isPending = useMemo(
    () => isEmailIncludedInList(data.pendingAssignees, user?.data.email),
    [data.pendingAssignees, user],
  )

  return (
    <div>
      <motion.div
        layout={isExpanded}
        initial={{ height: '40px' }}
        animate={{
          height: isExpanded ? 'auto' : showAdditionalLine ? '60px' : '40px',
        }}
        transition={{
          type: 'spring',
          damping: 20,
          stiffness: 300,
        }}
        onClick={handleTaskClick}
        className={
          clsx(
            styles.taskItemWrapper,
            isExpanded && styles.taskItemWrapperExpanded,
            isCompletedTask && styles.taskCompleted,
            isDragging && styles.taskDragging,
          ) + ' task-list-item'
        }
        style={{ zIndex: 1 }}
      >
        <div
          className={clsx(
            styles.categoryIconWrapper,
            isExpanded && styles.categoryIconWrapperExpanded,
          )}
          style={{ backgroundColor: color }}
        >
          {isCurriculumView && (
            <Popover
              content={t('curriculum.change-task-group')}
              placement="left"
            >
              <div onClick={(e) => e.stopPropagation()}>
                <GroupDropdown
                  onChange={handleGroupChange}
                  icon={icon.label}
                  isReadonly={isReadonly || !canEditGroup}
                  isDisabled={isDelegatedToMe || isCurriculumLesson}
                  groupName={getGroupNameWithOwnerIfNeeded(data, groups)}
                />
              </div>
            </Popover>
          )}
          {!isCurriculumView && (
            <div onClick={(e) => e.stopPropagation()}>
              <GroupDropdown
                onChange={handleGroupChange}
                icon={icon.label}
                isReadonly={isReadonly || !canEditGroup}
                isDisabled={isDelegatedToMe || isCurriculumLesson}
                groupName={getGroupNameWithOwnerIfNeeded(data, groups)}
              />
            </div>
          )}
        </div>
        <div className={styles.contentWrapper + ' task-list-item-content'}>
          {!hideStatus && (
            <StatusDropdown
              value={selectedStatus}
              style={{
                width: 115,
                marginRight: 8,
                opacity: isCompletedTask ? 0.8 : 1,
              }}
              onChange={handleStatusChange}
              isReadonly={isReadonly || !canEditStatus}
            />
          )}
          <span
            className={
              clsx(styles.taskTitle, isCompletedTask && styles.crossedOut) +
              ' task-list-item-content-title'
            }
          >
            {parentTitle && (
              <span className={styles.parentTask}>{parentTitle}</span>
            )}
            <span>
              {title}
              {showAdditionalLine && (
                <>
                  <br />
                  {completedItemsMessage}
                </>
              )}
            </span>
          </span>
          {isExpanded &&
            data.taskDetails?.description &&
            isJson(data.taskDetails?.description || '') && (
              <div
                style={{ paddingTop: 8 }}
                dangerouslySetInnerHTML={{
                  __html: extractTextFromJson(
                    JSON.parse(data.taskDetails?.description).root || '',
                  ),
                }}
              />
            )}
          {isExpanded &&
            data.taskDetails?.description &&
            !isJson(data.taskDetails?.description || '') && (
              <div
                style={{ paddingTop: 8 }}
                dangerouslySetInnerHTML={{
                  __html: decode(data.taskDetails?.description || ''),
                }}
              />
            )}
        </div>
        {!showAdditionalLine && completedItemsMessage && (
          <span style={{ paddingLeft: 4 }}>{completedItemsMessage}</span>
        )}
        {onViewFlowViewer && (
          <PlanningToolbar
            task={data}
            onViewTaskEditor={() => onViewTaskEditor?.(data)}
            onViewFlowViewer={() => onViewFlowViewer(data)}
          />
        )}

        {!onViewFlowViewer &&
          !isCompletedTask &&
          !isCurriculumTask &&
          !isPending && (
            <TaskToolbar
              task={data}
              flagged={flagged}
              isTaskExpanded={isExpanded}
              isReadonly={isReadonly}
              showChatIcon={showChatIcon}
              onToggle={onToggle}
            />
          )}

        {!onViewFlowViewer &&
          onDismiss &&
          isCompletedTask &&
          !isCurriculumTask && (
            <CompletedTaskToolbar task={data} onDismiss={onDismiss} />
          )}
      </motion.div>
      <DateSelectorModal
        isOpen={showDateSelector}
        onClose={() => setShowDateSelector(false)}
        onResolve={modalPromiseResolve}
        min={minDate}
      />
    </div>
  )
}
