import { createWithEqualityFn } from 'zustand/traditional'
import { immer } from 'zustand/middleware/immer'
import { persist, devtools } from 'zustand/middleware'
import { createAllTasksSlice, AllTasksState } from 'services/AllTasks.slice'
import { createAuthSlice, AuthState } from 'services/Auth.slice'
import { createThemeSlice, ThemeState } from 'services/theme.slice'
import { createTasksSlice, TasksState } from 'services/Tasks.slice'
import { createTaskFlowSlice, TaskFlowState } from 'services/TaskFlow.slice'
import { createCalendarSlice, CalendarState } from 'services/Calendar.slice'
import {
  createCategoriesSlice,
  CategoriesState,
} from 'services/Categories.slice'
import {
  createNotificationSlice,
  NotificationState,
} from 'services/Notification.slice'
import {
  createDelegationSlice,
  DelegationState,
} from 'services/Delegation.slice'
import { createSettingsSlice, SettingsState } from 'services/Settings.slice'
import {
  createTemplateTaskSlice,
  TemplateTaskState,
} from 'services/Templates.slice'
import { createChatSlice, ChatState } from 'services/Chat.slice'
import {
  createIntegrationSlice,
  IntegrationState,
} from 'services/Integration.slice'
import {
  createPermissionSlice,
  PermissionState,
} from 'services/Permission.slice'
import {
  createCurriculumsSlice,
  CurriculumsState,
} from 'services/Curriculums.slice'

type StoreType = ThemeState &
  AllTasksState &
  AuthState &
  CategoriesState &
  TasksState &
  TaskFlowState &
  CalendarState &
  NotificationState &
  DelegationState &
  SettingsState &
  TemplateTaskState &
  ChatState &
  IntegrationState &
  PermissionState &
  CurriculumsState

const useStore = createWithEqualityFn<
  StoreType,
  [
    ['zustand/devtools', never],
    ['zustand/persist', never],
    ['zustand/immer', never],
  ]
>(
  devtools(
    persist(
      immer((...args) => ({
        ...createThemeSlice(...args),
        ...createAllTasksSlice(...args),
        ...createAuthSlice(...args),
        ...createCategoriesSlice(...args),
        ...createTasksSlice(...args),
        ...createTaskFlowSlice(...args),
        ...createCalendarSlice(...args),
        ...createNotificationSlice(...args),
        ...createDelegationSlice(...args),
        ...createSettingsSlice(...args),
        ...createTemplateTaskSlice(...args),
        ...createChatSlice(...args),
        ...createIntegrationSlice(...args),
        ...createPermissionSlice(...args),
        ...createCurriculumsSlice(...args),
      })),
      {
        name: 'tepin-root',
        version: 12,
      },
    ),
    {
      name: 'TepinTasks',
    },
  ),
)

export default useStore
