import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import api from 'utils/axios'
import apiBase from 'utils/axiosBase'
import { TokenType, checkToken } from 'utils/tokens'
import type { StateCreator } from 'zustand'
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { camelizeKeys } from 'humps'
import { CurrentStatus } from 'types/Tasks'
import { getTimeZone } from 'utils/IntlUtlis'
import * as serviceWorkerRegistration from '../serviceWorkerRegistration'

const firebaseConfig = {
  apiKey: 'AIzaSyCpzIRhDvENTtcIq_TJJtuVtETZb2He2Lc',
  authDomain: 'tepintasks-dev-web.firebaseapp.com',
  projectId: 'tepintasks-dev-web',
  storageBucket: 'tepintasks-dev-web.appspot.com',
  messagingSenderId: '813833055091',
  appId: '1:813833055091:web:0ae0fe3aec252859c63306',
  measurementId: 'G-S7Q45L5P87',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export type LoginForm = {
  email: string
  password: string
}

export type RegistrationForm = {
  firstName: string
  lastName: string
  email: string
  password: string
  userType: number
  passwordConfirm: string
  emailVerified: boolean
}

export type PasswordResetParams = {
  password: string
  uid: string
  token: string
}

export type PasswordChangeParams = {
  old_password: string
  new_password1: string
  new_password2: string
}

type SocialAuthenticationOptions = {
  prompt?: string
  response_type?: string
  scope?: string
  client_id?: string
  redirect_uri?: string
}

type SnakeUser = {
  id: string
  email: string
  last_name: string
  first_name: string
  type_id: string
  otp_enabled: boolean
}

export type UserType = {
  data: {
    id: string
    email: string
    lastName: string
    firstName: string
    typeId: string
    otpEnabled: boolean
  }
}

type RefreshResponse = AxiosResponse<{
  access: string
  access_token_expiration: string
  refresh: string
}>

type RegisterBody = {
  email: string
  password1: string
  password2: string
  last_name: string
  first_name: string
  user_type: number
  email_verified?: boolean
}

export type ApiState = {
  api?: (
    method: string,
    url: string,
    data?: any,
    config?: any,
  ) => Promise<AxiosResponse>
}

let activeRequests = 0

export interface AuthState extends ApiState {
  isRefreshing: boolean
  isNormalizing: boolean
  isConfirming: boolean
  shouldReload: boolean
  lastNormalizationUser: string
  lastNormalizationDate: string
  tokenPromise?: Promise<RefreshResponse>
  token?: TokenType
  refreshToken: () => Promise<void>
  getToken: () => Promise<TokenType>
  user?: UserType
  logout: () => void
  confirmEmail: (key: string) => Promise<void>
  storeLogin: (
    accessToken: string,
    refreshToken: string,
    user: SnakeUser,
  ) => Promise<void>
  login: (values: LoginForm) => Promise<AxiosResponse>
  submitCodeLogin: (code: string, id: string) => Promise<void>
  requestPasswordReset: (email: string) => Promise<void>
  socialLogin: (provider: string, token: string) => Promise<void>
  confirmPasswordReset: (params: PasswordResetParams) => Promise<void>
  requestPasswordChange: (params: PasswordChangeParams) => Promise<string>
  register: (
    values: RegistrationForm,
  ) => Promise<{ status: number; message: string }>
  socialAuthentication: (provider: string) => void
  normalizeTasksIfNeeded: (config: any) => Promise<void>
  updateUserState: (user: UserType['data']) => void
  generate2fa: () => Promise<AxiosResponse>
  disable2fa: () => Promise<AxiosResponse>
  verify2faCode: (code: string) => Promise<AxiosResponse>
  registerFCM: () => Promise<void>
}

export const createAuthSlice: StateCreator<
  AuthState,
  [['zustand/immer', never]]
> = (set, get) => ({
  isRefreshing: false,
  isNormalizing: false,
  isConfirming: false,
  shouldReload: false,
  lastNormalizationUser: '',
  lastNormalizationDate: '',
  register: async (values) => {
    const registerUrl = values.emailVerified
      ? '/registration/'
      : '/auth/registration/'
    const registerBody: RegisterBody = {
      email: values.email,
      password1: values.password,
      password2: values.password,
      last_name: values.lastName,
      first_name: values.firstName,
      user_type: values.userType,
    }
    if (values.emailVerified) registerBody.email_verified = true
    const response = await apiBase.post(registerUrl, registerBody)

    if (response.status >= 200) {
      return { status: response.status, message: response.data.detail }
    }

    throw new Error()
  },
  confirmEmail: async (key) => {
    if (!get().isConfirming) {
      set((draft) => {
        draft.isConfirming = true
      })
      try {
        const response = await apiBase.post('/auth/account-confirm-email/', {
          key,
        })
        if (response.status !== 200) {
          throw new Error()
        }
      } finally {
        set((draft) => {
          draft.isConfirming = false
        })
      }
    }
  },
  logout: async () => {
    if (Notification.permission === 'granted') {
      // @ts-ignore
      await get().deleteSubscription()
    }
    apiBase.post('/auth/logout/')

    set((prev) => {
      prev.user = undefined
      prev.token = undefined
      prev.isNormalizing = false
    })
    localStorage.removeItem('tepin-root')
    window.location.reload()
  },
  storeLogin: async (
    accessToken: string,
    refreshToken: string,
    user: SnakeUser,
  ) => {
    set((draft) => {
      draft.token = {
        access: accessToken,
        refresh: refreshToken,
      }
      draft.user = {
        data: {
          id: user.id,
          email: user.email,
          lastName: user.last_name,
          firstName: user.first_name,
          typeId: user.type_id,
          otpEnabled: user.otp_enabled,
        },
      }
    })

    const hasFirebaseMessagingSupport = await isSupported()
    if (
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      hasFirebaseMessagingSupport
    ) {
      if (navigator.serviceWorker.controller?.state === 'activated') {
        try {
          await get().registerFCM()
        } catch (error) {
          console.error('Error subscribing to push notifications:', error)
        }
      } else {
        if (!navigator.serviceWorker.controller)
          serviceWorkerRegistration
            .registerFCM()
            .then(() => get().registerFCM())
        navigator.serviceWorker.controller?.addEventListener(
          'statechange',
          (e: any) => {
            get().registerFCM()
          },
        )
      }
    }
  },
  submitCodeLogin: async (code, id) => {
    const response = await apiBase.post('/login/code/', { code, id })

    if (response.status === 200) {
      const { access_token, refresh_token, user } = response.data
      get().storeLogin(access_token, refresh_token, user)
    }

    if (response.status === 401) {
      set((draft) => {
        draft.user = undefined
      })
    }

    if (response.status >= 400) {
      let message = ''
      for (let value of Object.values(response.data.detail)) {
        message = value as string
      }

      throw new Error(message)
    }
  },
  login: async (values) => {
    const response = await apiBase.post('/login/', values)

    if (response.status === 200 && response.data.access_token) {
      const { access_token, refresh_token, user } = response.data
      set((draft) => {
        draft.isNormalizing = false
      })
      get().storeLogin(access_token, refresh_token, user)
    }
    if (response.status === 401) {
      set((draft) => {
        draft.user = undefined
      })
    }
    if (response.status >= 400) {
      let message = ''
      for (let value of Object.values(response.data.detail)) {
        message = value as string
      }
      throw new Error(message)
    }
    return response
  },
  socialAuthentication: (provider) => {
    const baseURL = {
      google: 'https://accounts.google.com/o/oauth2/v2/auth',
      linkedin: 'https://www.linkedin.com/oauth/v2/authorization',
    }[provider]
    const options = {
      linkedin: {
        response_type: 'code',
        scope: 'r_emailaddress r_liteprofile',
        client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID!,
        redirect_uri: `${process.env.REACT_APP_LOGIN_CALLBACK_URI}/linkedin`,
      },
      google: {
        prompt: 'consent',
        response_type: 'token',
        scope: 'openid email profile',
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
        redirect_uri: `${process.env.REACT_APP_LOGIN_CALLBACK_URI}/google`,
      },
    }[provider] as SocialAuthenticationOptions

    const params = new URLSearchParams(options).toString()

    window.location.href = `${baseURL}?${params}`
  },
  socialLogin: async (provider, token) => {
    const body = {
      linkedin: {
        code: token,
      },
      google: {
        access_token: token,
        code: '',
        id_token: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      },
    }[provider]

    const response = await apiBase.post(`/auth/${provider}/`, body)

    if (response.status === 200) {
      set((draft) => {
        draft.token = {
          access: response.data.access_token,
          refresh: response.data.refresh_token,
        }
        draft.user = {
          data: {
            id: response.data.user.id,
            email: response.data.user.email,
            lastName: response.data.user.last_name,
            firstName: response.data.user.first_name,
            typeId: response.data.user.type_id,
            otpEnabled: response.data.user.otp_enabled,
          },
        }
      })
    } else {
      throw new Error()
    }
  },
  requestPasswordReset: async (email) => {
    const response = await apiBase.post('/auth/password/reset/', { email })

    if (response.status === 200) {
      return response.data
    }

    throw new Error()
  },
  confirmPasswordReset: async (params) => {
    const response = await apiBase.post('/auth/password/reset/confirm/', {
      new_password1: params.password,
      new_password2: params.password,
      uid: params.uid,
      token: params.token,
    })

    if (response.status === 200) {
      return response.data
    }

    if (response.status >= 400) {
      let message = ''
      for (let value of Object.values(response.data.detail)) {
        message = value as string
      }

      throw new Error(message)
    }

    throw new Error('Something went wrong, please try again later')
  },
  requestPasswordChange: async (params) => {
    const { getToken } = get()
    const token = await getToken()
    const headers = {
      Authorization: `Bearer ${token.access}`,
    }

    const response = await apiBase.post('/auth/password/change/', params, {
      headers,
    })

    if (response.status === 200) {
      return response.data.detail
    }

    throw new Error()
  },
  refreshToken: async () => {
    if (!get().isRefreshing) {
      set((draft) => {
        draft.isRefreshing = true
      })
      const tokens = get().token
      const tokenPromise = apiBase.post('/auth/token/refresh/', {
        refresh: tokens!.refresh,
      })
      set((draft) => {
        draft.tokenPromise = tokenPromise
      })
    }
    const response = await get().tokenPromise

    set((draft) => {
      draft.token = response?.data
      draft.isRefreshing = false
    })
  },
  getToken: async () => {
    const token = get().token
    if (!token) {
      throw new Error('User not logged in')
    }

    const isValid = checkToken(token)

    if (isValid) return token as TokenType
    else {
      await get().refreshToken()
      return get().getToken()
    }
  },
  api: async (
    method: string,
    url: string,
    data = null,
    config = {},
  ): Promise<AxiosResponse> => {
    try {
      const token = await get().getToken()
      config.headers = !!config.headers ? config.headers : {}
      config.headers.Authorization = `Bearer ${token.access}`
      activeRequests = activeRequests + 1
    } catch (error) {
      set((draft) => {
        draft.user = undefined
      })
      throw error
    }

    try {
      await get().normalizeTasksIfNeeded(config)
      const response = await api.request({ method, url, data, ...config })
      // Decrement the active requests counter
      activeRequests = activeRequests - 1
      return response
    } catch (error: any) {
        activeRequests = activeRequests - 1
      if (
        error.response &&
        error.response.status === 400 &&
        error?.response?.data?.detail?.current_status
      ) {
        const currentStatus: CurrentStatus =
          error?.response?.data?.detail?.current_status
        // @ts-ignore
        // Typescript doesn't like it when accessing calls from another slice
        get().setSubscriptionStatus(camelizeKeys(currentStatus))
      }
      if (error.response && error.response.status === 401) {
        try {
          // Attempt token refresh
          await get().refreshToken()
          // Retry the original request
          const retryResponse = await get().api!(method, url, data, config)
          return retryResponse.data
        } catch (refreshError) {
          throw refreshError
        }
      }
      throw error
    } finally {
      // Check if there are no active requests and trigger reload if needed
      if (activeRequests === 0 && get().shouldReload) {
        set((draft) => {
          draft.shouldReload = false
        })
        window.location.reload()
      }
    }
  },
  normalizeTasksIfNeeded: async (config) => {
    let shouldNormalizeTasks = false
    const currentUser = get().user?.data.id
    const lastNormalizationUser = get().lastNormalizationUser
    const lastNormalizationDate = get().lastNormalizationDate
    if (get().isNormalizing) {
      if (
        dayjs(lastNormalizationDate).isValid() &&
        dayjs(lastNormalizationDate).isBefore(
          dayjs().add(-30, 'second'),
          'second',
        )
      ) {
        set((draft) => {
          draft.isNormalizing = false
        })
      } else {
        return
      }
    }
    if (lastNormalizationDate && lastNormalizationUser === currentUser) {
      if (dayjs(lastNormalizationDate).isValid()) {
        shouldNormalizeTasks = dayjs(lastNormalizationDate).isBefore(
          dayjs(),
          'day',
        )
      } else {
        shouldNormalizeTasks = true
      }
    } else {
      shouldNormalizeTasks = true
    }
    if (!shouldNormalizeTasks) {
      return
    }
    set((draft) => {
      draft.isNormalizing = true
      draft.lastNormalizationUser = currentUser || ''
      draft.lastNormalizationDate = dayjs().format()
    })

    try {
      const userTimezone = getTimeZone().value
      const response = await api.request({
        method: 'GET',
        url: `/task/normalization?user_timezone=${userTimezone}`,
        ...config,
      })
      if (response.status === 200) {
        set((draft) => {
          draft.isNormalizing = false
          draft.lastNormalizationUser = currentUser || ''
          draft.lastNormalizationDate = dayjs().format()
        })
        // Refresh the page to check for updates
        set((draft) => {
          draft.shouldReload = true
        })
      }
    } catch (error: any) {
      set((draft) => {
        draft.isNormalizing = false
        draft.lastNormalizationUser = currentUser || ''
        draft.lastNormalizationDate = dayjs().format()
      })
    } finally {
      set((draft) => {
        draft.isNormalizing = false
      })
      const events = [
        [
          'activeTasks',
          'delegatedTasks',
          'groupMembership',
          'integrations',
          'ownedGroup',
          'ownedGroupTotal',
          'totalRoutineInstances',
          'totalTasks',
        ],
      ]
      // @ts-ignore
      get().updateSubscriptionStatus(events)
    }
  },
  updateUserState: (user: UserType['data']) => {
    set((draft) => {
      draft.user = {
        data: {
          email: user.email,
          firstName: user.firstName,
          id: user.id,
          lastName: user.lastName,
          typeId: user.typeId,
          otpEnabled: user.otpEnabled,
        },
      }
    })
  },
  generate2fa: async () => {
    const response = await get().api!('POST', '/auth/otp/generate/')

    return response
  },
  disable2fa: async () => {
    const response = await get().api!('POST', '/auth/otp/disable/')
    if (response.status === 200) {
      set((draft) => {
        draft.user = { data: response.data.user }
      })
    }
    return response
  },
  verify2faCode: async (code) => {
    const response = await get().api!('POST', '/auth/otp/verify/', {
      token: code,
    })
    if (response.status === 200) {
      set((draft) => {
        if (draft.user) {
          draft.user.data.otpEnabled = true
        }
      })
    }
    return response
  },
  registerFCM: async () => {
    const messaging = getMessaging(app)
    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_PUSH_PUBLIC_KEY,
      })
      if (currentToken) {
        // @ts-ignore
        await get().postSubscription({
          device_type: 'web',
          device_token: currentToken,
        })
      } else {
        // Show permission request UI
        console.error(
          'No registration token available. Request permission to generate one.',
        )
      }
    } catch (error) {
      // Show permission request UI
      console.error(
        'No registration token available. Request permission to generate one.',
      )
    }
  },
})
