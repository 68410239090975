import { DeleteOutlined } from '@ant-design/icons'
import { App, Button, Popconfirm, Space, Spin, Typography } from 'antd'
import useStore from 'hooks/useStore'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CommitChangesFunction, TaskItem } from 'types/Tasks'
import ConfirmDialog from './ConfirmDialog'

type DeleteTasksDialogProps = {
  onExecute: () => void | CommitChangesFunction
  showDialog: boolean
  setShowDialog: Dispatch<SetStateAction<boolean>>
  taskHasChildren: boolean
  taskIds: string[]
  children?: JSX.Element
  isDeletingTemplate?: boolean
}

const { Title, Text } = Typography

const getHeaderMessage = (
  isLoading: boolean,
  taskHasChildren: boolean,
  numberOfDescendants: number,
  isDeletingMultipleTasks: boolean,
) => {
  if (!taskHasChildren) {
    return 'description'
  }
  if (isLoading) {
    return 'description-checking'
  }
  if (taskHasChildren && numberOfDescendants) {
    return isDeletingMultipleTasks
      ? 'description-multiple-subtasks'
      : 'description-subtasks'
  }
  return 'description'
}

const DeleteTasksDialog = ({
  taskHasChildren = false,
  taskIds,
  onExecute = () => {},
  children,
  showDialog,
  setShowDialog,
  isDeletingTemplate,
}: DeleteTasksDialogProps) => {
  const { t } = useTranslation()
  const app = App.useApp()
  const getDescendants = useStore((state) => state.getDescendants)
  const [numberOfDescendants, setNumberOfDescendants] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const setLastModifiedTasks = useStore((state) => state.setLastModifiedTasks)
  const [deleteAction, setDeleteAction] = useState<string>('')
  const deleteTaskById = useStore((state) => state.deleteTaskById)
  const refTitle = useRef<HTMLInputElement>(null)
  const refContent = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent | TouchEvent) => {
      if (
        !isLoading &&
        refTitle.current &&
        refContent.current &&
        !refTitle.current.contains(event.target as Node) &&
        !refContent.current.contains(event.target as Node)
      ) {
        closeModal()
      }
    }

    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  useEffect(() => {
    if (showDialog && taskHasChildren && taskIds.length) {
      Promise.all<TaskItem[]>(
        taskIds.map((taskId) => {
          return getDescendants(taskId, false)
        }),
      ).then((descendants) => {
        const tasks = descendants
          .map((descendant) => {
            return descendant.map((task) => task.id)
          })
          .flat()
        const uniqueTasks = tasks.filter(
          (value, index) =>
            tasks.indexOf(value) === index && taskIds.indexOf(value) === -1,
        )
        setNumberOfDescendants(uniqueTasks.length)
        setIsLoading(false)
      })
    }
    if (showDialog && !taskHasChildren) {
      setTimeout(function () {
        setIsLoading(false)
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDescendants, showDialog, taskHasChildren])

  const headerMessageCode = getHeaderMessage(
    isLoading,
    taskHasChildren,
    numberOfDescendants,
    taskIds.length > 1,
  )

  const callDeleteTaskEndpoint = (withChildren: boolean) => {
    if (!taskIds.length) {
      return
    }

    if (isDeletingTemplate) {
      app.notification.info({
        message: t('task-form.template-will-be-deleted', {
          ns: 'validation',
        }),
      })
    }
    deleteTaskById(taskIds, withChildren)
      .then(() => {
        setLastModifiedTasks([{ id: taskIds[0] }])
        app.notification.success({
          message: t('new-task-form.delete-success'),
        })
      })
      .catch((error) => {
        app.notification.error({
          message: error.message,
        })
      })
      .finally(() => {
        setShowDialog?.(false)
        onExecute()
      })
  }

  const deleteTaskWithDescendants = () => {
    callDeleteTaskEndpoint(true)
    setDeleteAction('')
  }

  const deleteSelectedTaskOnly = () => {
    callDeleteTaskEndpoint(false)
    setDeleteAction('')
  }

  const closeModal = () => {
    setShowDialog?.(false)
    setIsLoading(true)
  }

  const deleteParentOrAllComponent = (
    <Spin spinning={isLoading && taskHasChildren}>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button onClick={() => closeModal()} size="small">
          {t('new-task-form.delete-task-prompt.cancel', {
            ns: 'common',
          })}
        </Button>
        <Button
          ghost
          type="primary"
          onClick={() => {
            closeModal()
            setDeleteAction('deleteParent')
          }}
          size="small"
        >
          {t('new-task-form.delete-task-prompt.delete-one', {
            ns: 'common',
          })}
        </Button>
        <Button
          danger
          onClick={() => {
            closeModal()
            setDeleteAction('deleteAll')
          }}
          size="small"
          icon={<DeleteOutlined />}
        >
          {t('new-task-form.delete-task-prompt.delete-all', {
            ns: 'common',
          })}
        </Button>
      </Space>
    </Spin>
  )

  const deleteSingleTaskComponent = (
    <Spin spinning={isLoading && taskHasChildren}>
      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button onClick={() => closeModal()} size="small">
          {t('my-today-page.delete-modal.cancel-text', {
            ns: 'common',
          })}
        </Button>
        <Button
          danger
          onClick={() => {
            closeModal()
            deleteSelectedTaskOnly()
          }}
          size="small"
          icon={<DeleteOutlined />}
        >
          {t('my-today-page.delete-modal.ok-text', {
            ns: 'common',
          })}
        </Button>
      </Space>
    </Spin>
  )

  return (
    <>
      <Popconfirm
        open={showDialog}
        title={
          <div ref={refTitle}>
            <Title level={4} style={{ marginBottom: 4 }}>
              {t('task-form.delete-task.title', {
                ns: 'validation',
              })}
            </Title>
          </div>
        }
        description={
          <div ref={refContent}>
            <div style={{ paddingBottom: 16 }}>
              <Text style={{ whiteSpace: 'pre-line', display: 'inline-block' }}>
                {t(`task-form.delete-task.${headerMessageCode}`, {
                  ns: 'validation',
                  count: numberOfDescendants,
                })}
              </Text>
            </div>
            {taskHasChildren && numberOfDescendants
              ? deleteParentOrAllComponent
              : deleteSingleTaskComponent}
          </div>
        }
        icon={null}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        {children}
      </Popconfirm>
      <ConfirmDialog
        action={deleteAction}
        numberOfDescendants={numberOfDescendants}
        onCancel={() => setDeleteAction('')}
        onDeleteAllTasks={deleteTaskWithDescendants}
        onDeleteParentTask={deleteSelectedTaskOnly}
        open={deleteAction ? true : false}
      />
    </>
  )
}

export default DeleteTasksDialog
