import { Avatar, Tooltip } from 'antd'
import UserAvatar from 'components/UserAvatar'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PendingAssignee, TaskAssignees } from 'types/Tasks'
import { AssigneeInvitationStatus } from 'utils/taskUtils'

type UserAvatarWithTooltipProps = {
  pendingAssignees?: PendingAssignee[]
  taskAssignees?: TaskAssignees[]
  size?: number
}

const UserAvatarWithTooltip = ({
  pendingAssignees,
  taskAssignees,
  size = 22,
}: UserAvatarWithTooltipProps) => {
  const { t } = useTranslation()

  const userList = useMemo(
    () =>
      (taskAssignees || [])
        .map(({ assigneeName, avatar }) => ({
          assigneeName,
          avatar,
          status: AssigneeInvitationStatus.ACCEPTED as string,
          createdAt: '',
        }))
        .concat(
          (pendingAssignees || []).map(
            ({ email, avatar, status, createdAt }) => ({
              assigneeName: email,
              avatar,
              status,
              createdAt: createdAt || '',
            }),
          ),
        ),
    [pendingAssignees, taskAssignees],
  )

  if ((taskAssignees || []).length + (pendingAssignees || []).length === 0) {
    return null
  }

  return (
    <Avatar.Group
      maxCount={2}
      maxStyle={{
        color: 'var(--avatar-max-count-solid)',
        backgroundColor: 'var(--avatar-max-count-transparent)',
        ...(size ? { width: size, height: size, fontSize: size - 2 } : {}),
      }}
    >
      {userList.map(({ assigneeName, avatar, status }, index) => (
        <Tooltip
          key={index}
          title={t('my-today-page.delegated-to', {
            ns: 'common',
            name: assigneeName,
            status,
          })}
        >
          <UserAvatar
            label={assigneeName}
            size={size}
            delegationStatus={status}
            image={avatar}
            avatarStyles={{ margin: 'auto' }}
          />
        </Tooltip>
      ))}
    </Avatar.Group>
  )
}

export default UserAvatarWithTooltip
